/**
 * Removes the experiment root from the URL pathname.
 */
export function removeExperimentRootFromUrl(url: URL): URL {
  if (!url.pathname.startsWith('/test/')) {
    return url;
  }
  // Example: '/test/albus/variant_a'
  const parts = url.pathname.split('/').slice(4);
  url.pathname = `/${parts.join('/')}`;
  return url;
}
